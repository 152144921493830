body {
  margin: 0;
  font-family: "futura-pt," Helvetica Neue",Helvetica,Arial,sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8fb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Ringside Regular-Black";
  src: local("Ringside Regular-Black"),
    url(../src/assets/fonts/RingsideRegular-Black.otf) format("opentype");
}

@font-face {
  font-family: "Ringside Regular-Bold";
  src: local("Ringside Regular-Bold"),
    url(../src/assets/fonts/RingsideRegular-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Ringside Regular-Book";
  src: local("Ringside Regular-Book"),
    url(../src/assets/fonts/RingsideRegular-Book.otf) format("opentype");
}