.action-container {
  border: none;
  border-left: 2px solid #CCC;
}

@media (max-width: 900px) {
  .action-container {
    border: none;
    border-top: 2px solid #CCC;
  }
}