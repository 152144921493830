.clarifier-logs-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.clarifier-td, .clarifier-th {
  border: 1px solid #dddddd6e;
  text-align: left;
  padding: 8px;
  font-weight: 900;
  font-size: 14px;
}

.clarifier-td {
  font-weight: 100;
}

.clarifier-td-name {
  white-space: nowrap;
}

/* .clarifier-tr:nth-child(even) {
  background-color: #dddddd6e;
} */