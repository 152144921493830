.custom-table .ant-table-thead>tr>th {
  background-color: #ED8622;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 12px;
}

.analytics-content-plot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
}