.step-progress-bar {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  flex-direction: column;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
}

.step.active .step-number {
  color: #fff;
}

.step .step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3f3f3;
  color: #3f51b5;
  font-weight: bold;
  margin-bottom: 4px;
  flex: none;
}

.step .step-label {
  font-size: 16px;
  color: #888;
  margin-left: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: black
}