.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Adjust the height as needed */
}

.spinner-overlay {
  position: relative;
  text-align: center;
}

.static-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ED8622;
}